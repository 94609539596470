import React from 'react';
// images
import Image from '../assets/avatar.svg'
//icons
import {FaGithub, FaYoutube, FaDribble, FaDribbble, FaLinkedin } from 'react-icons/fa'
// type animation
import {TypeAnimation} from 'react-type-animation'
// motion
import {motion} from 'framer-motion'
// variants
import {fadeIn} from '../variants'
import {Link} from 'react-scroll'

const Banner = () => {
  return (
    <section className='min-h-[85vh] lg:min-h-[78vh] flex items-center' id='home'>
    <div className="container mx-auto">
      <div className='flex flex-col gap-y-8 lg:flex-row lg:items-center lg:gap-x-12 '>
        {/* text */}
        <div className='flex-1 text-center font-secondary lg:text-left'>
          <motion.h1 
            variants={fadeIn('up', 0.3)} 
            initial='hidden' 
            whileInView={'show'} 
            viewport={{once: false, amount: 0.7}} 
            className='text-[55px] font-bold leading-[0.8] lg:text-[110px]'>
            MEHDI <span>LOTFI</span>
          </motion.h1>
          <motion.div             
            variants={fadeIn('up', 0.3)} 
            initial='hidden' 
            whileInView={'show'} 
            viewport={{once: false, amount: 0.7}} 
            className='mb-6 text-[36px] lg:text-[60px] font-secondary 
            font-semibold uppercase leading-[1]'>
            <span className='text-white mr-4'>I am a</span>
            <TypeAnimation 
              sequence={[
                'Web Developer',
                2000,
                'Front-End Developer',
                2000,
                'Content Editor',
                2000,
              ]} 
              speed={50}
              className='text-accent'
              wrapper='span'
              repeat={Infinity}
              />
          </motion.div>
          <motion.p             
            variants={fadeIn('up', 0.)} 
            initial='hidden' 
            whileInView={'show'} 
            viewport={{once: false, amount: 0.7}}  className='mb-8 max-w-lg mx-auto lg:mx-0'>
            Hey, I'm Mehdi, 21 years old, from Florida
          </motion.p>
          <motion.div             
            variants={fadeIn('up', 0.6)} 
            initial='hidden' 
            whileInView={'show'} 
            viewport={{once: false, amount: 0.7}}  
            className='flex max-w-max gap-x-6 items-center mb-12 mx-auto lg:mx-0'>
              
            <Link to='contact'
            activeClass='active'
            smooth={true}
            spy={true}
            className='btn btn-lg btn-link text-center flex items-center'> Contact Me
            </Link>

            <Link to='work'
            activeClass='active'
            smooth={true}
            spy={true}
            className='text-gradient btn-link'> My Projects
            </Link>

          </motion.div>
          {/*socials*/}
          <motion.div             
            variants={fadeIn('up', 0.7)} 
            initial='hidden' 
            whileInView={'show'} 
            viewport={{once: false, amount: 0.7}}  
            className='flex text-[20px] gap-x-6 max-w-max mx-auto lg:mx-0'>
            <a href='https://github.com/02mehdii' target='_blank'>
              <FaGithub />
            </a>
            <a href='https://www.linkedin.com/in/mehdi-lotfi-327343289/' target='_blank'>
              <FaLinkedin />
            </a>
          </motion.div>
        </div>
        {/* image */}

      </div>
    </div>
    </section>
  );
};

export default Banner;
