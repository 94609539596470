import React from 'react';
// motion
import {motion} from 'framer-motion'
// variants
import {fadeIn} from '../variants'
// img
import Img1 from '../assets/project-1-img.png'
import Img2 from '../assets/nike-store.png'
import Img3 from '../assets/art.png'

const Work = () => {
  return ( 
    <section className='section' id='work'>
    <div className="container mx-auto">
      <div className='flex flex-col lg:flex-row gap-x-10'>
        <motion.div
          variants={fadeIn('right', 0.3)}
          initial="hidden"
          whileInView={"show"}
          viewport={{once: false, amount: 0.3}} 
          className='flex-1 flex flex-col gap-y-12 mb-10 lg:mb-0'>
          {/* text */}
          <div>
            <h2 className='h2 leading-tight text-accent'>
              My Latest <br /> 
              Projects
            </h2>
          </div>
          {/* image */}
          <div className='group relative overflow-hidden border-2 border-white/50 rounded-xl'>
            {/* overlay*/}
            <a href='https://amazing-cucurucho-74eae9.netlify.app' target='_blank'>
            <div className='group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300'></div>
            </a>
            {/* img */}
            <img className='group-hover:scale-125 transition-all duration-500' src={Img1} alt='' />
            {/* pretitle */}
            <div className='absolute -bottom-full left-12 group-hover:bottom-24 mb-12 transition-all duration-500 z-50'>
              <span className='text-gradient'>AI Article Summarizer  </span>
            </div>
            {/* title */}
            <div className='absolute -bottom-full left-12 group-hover:bottom-14 transition-all duration-700 z-50'>
              <span className='text-xl text-white'>This program uses OpenAi's Api to provide results. After sending a link there will be a 10-15 sec wait time.</span>
            </div>
          </div>
        </motion.div>
        <motion.div
          variants={fadeIn('left', 0.2)}
          initial="hidden"
          whileInView={"show"}
          viewport={{once: false, amount: 0.3}} 
          className='flex-1 flex flex-col gap-y-10'>
          {/* image */}
          <div className='group relative overflow-hidden border-2 border-white/50 rounded-xl'>
            {/* overlay*/}
            <a href='https://singular-pastelito-f0299e.netlify.app' target='_blank'>
            <div className='group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300'></div>
            </a>
            {/* img */}
            <img className='group-hover:scale-125 transition-all duration-500' src={Img3} alt='' />
            {/* pretitle */}
            <div className='absolute -bottom-full left-12 group-hover:bottom-24 mb-12 transition-all duration-500 z-50'>
              <span className='text-gradient'>Geometric Art Generator</span>
            </div>
            {/* title */}
            <div className='absolute -bottom-full left-12 group-hover:bottom-14 transition-all duration-700 z-50'>
              <span className='text-xl text-white'>Basic application that uses html, css, and javascript to make a canvas that randomizes art when generated</span>
            </div>
          </div>
          {/* image */}
          <div className='group relative overflow-hidden border-2 border-white/50 rounded-xl'>
            {/* overlay*/}
            <a href='https://bright-axolotl-a8b744.netlify.app/' target='_blank'>
            <div className='group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300'></div>
            </a>
            {/* img */}
            <img className='group-hover:scale-125 transition-all duration-500' src={Img2} alt='' />
            {/* pretitle */}
            <div className='absolute -bottom-full left-12 group-hover:bottom-24 mb-12 transition-all duration-500 z-50'>
              <span className='text-gradient'>Sneaker Store</span>
            </div>
            {/* title */}
            <div className='absolute -bottom-full left-12 group-hover:bottom-14 transition-all duration-700 z-50'>
              <span className='text-xl text-white'>This site is portrays a landing page for a sneaker store. The site was made using html, tailwindcss, javascript and react</span>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
    
    </section>
  );
};

export default Work;
