import React from 'react';
//images
import Logo from '../assets/Mehdi Lotfi-logos_transparent.png';

const Header = () => {
  return <header className='py-8'>
    <div className="container mx-auto">
      <div className='flex justify-between items-center'>
        {/* logo */}
        <a href='#'>
          <img src={Logo} alt='' />
        </a>
        {/* button */}
        <a href="https://drive.google.com/file/d/1iaebB4Kru0ovXOA4iplOnQLagl03Ysxn" target="_blank">
        <button className='btn btn-sm'>CV</button>
        </a>
      </div>
    </div>
    </header>;
};

export default Header;
