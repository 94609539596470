import React from 'react';
// icons
import {BsCheckAll} from 'react-icons/bs'
// motion
import {motion} from 'framer-motion'
// variants
import {fadeIn} from '../variants'

// services data
const services = [
  {
    name: 'Zeena Ai - Lead Web Developer ',
    description:
    `Led a team of developers in creating 
    blockchain-based applications, including Ai 
    integration and smart contract development.`,
  },
  {
    name: 'Pronto Pizza - Restaurant IT Specialist',
    description:
    `Developed and maintained the restaurant's electronic menu system, enhancing the ordering process and reducing
    wait times.`,
  },
  {
    name: 'Languages, Libraries & Frameworks',
    description:
    `JavaScript | HTML/CSS | SQL | Node.js | React.js | Express.js | MongoDB` 
    ,
  },
  {
    name: 'Tools',
    description:
    'VsCode |  Git | Bootstrap | Materialize | Tailwind | AWS',
  }
]

const Services = () => {
  return (
    <section className='section' id='services'>
    <div className="container mx-auto">
      <div className='flex flex-col lg:flex-row'>
        {/* text & image */}
        <motion.div 
        variants={fadeIn('right', 0.3)}
        initial="hidden"
        whileInView={"show"}
        viewport={{once: false, amount: 0.3}} 
        className='flex-1 lg:bg-services lg:bg-bottom bg-no-repeat
        mix-blend-lighten mb-12 lg:mb-0'>
          <h2 className='h2 text-accent mb-6'>Skills <span className='text-white'>&</span> <br></br> Work Experience</h2>
          <h3 className='h3 max-w-[455px] mb-16'>
          </h3>
        </motion.div>
        {/* services */}
        <motion.div 
          variants={fadeIn('left', 0.5)}
          initial="hidden"
          whileInView={"show"}
          viewport={{once: false, amount: 0.3}} 
          className='flex-1'>
          {/* services list */}
          <div>
            {services.map((service, index)=>{
              //destructure service
              const {name, description, link} = service;
              return(
              <div className='border-b border-white/20 h-[146px] mb-[36px] flex' key={index}>
                <div className='max-w-[476px]'>
                  <h4 className='text-[20px] tracking-wider font-primary font-semibold mb-3'>{name}</h4>
                  <p className='font-secondary leading-tight'>{description}</p>
                </div>
                <div className='flex flex-col flex-1 items-end'>
                  <a  className='btn w-9 h-9 mb-[42px] flex justify-center items-center'>
                    <BsCheckAll />
                  </a>
                 
                </div>
              </div>
              );
            })}
          </div>
        </motion.div>
      </div>
    </div>
    </section>
  );
};

export default Services;
