import React from 'react';
// countup
import CountUp  from 'react-countup';
// intersection observer
import {useInView} from 'react-intersection-observer'
// motion
import {motion} from 'framer-motion'
// variant 
import { fadeIn } from '../variants'

const About = () => {
  const [ref, inView] = useInView({
    threshold: 0.5,
  });
  return (
    <section className='section' id='about' ref={ref} >
    <div className="container mx-auto">
      <div className='flex flex-col gap-y-10 lg:flex-row lg:items-center lg:gap-x-20 lg:gap-y-0 h-screen'>
        {/* img */}
        <motion.div
        variants={fadeIn('right', 0.3)}
        initial="hidden"
        whileInView={"show"}
        viewport={{once: false, amount: 0.3}}
        className='flex-1 bg-about bg-contain bg-no-repeat h-[640px] 
        mix-blend-lighten bg-top'>
        </motion.div>
        {/* text */}
        <motion.div         
          variants={fadeIn('left', 0.5)}
          initial="hidden"
          whileInView={"show"}
          viewport={{once: false, amount: 0.3}} 
          className='flex-1'>
           <h2 className='h2 text-accent'>About me.</h2>
           <h3 className='h3 mb-4'>
            I'm a Web Developer with over 1 year experience.
           </h3>
           <p className='mb-6 '>
           Hey there! Im mehdi, an aspiring and motivated web developer with 1 year of hands-on experience
           in front-end and web development, proficient in various frameworks and languages and seeking opportunities
           to contribute my skills and creativity to innovative projects.
           <br /> When I'm not coding, you'll find me trying many different side hustles and on the off time playing soccer.
           </p>
           {/* stats */}
           <div className='flex gap-x-6 lg:gap-x-10 mb-12'>
              <div>
                <div className='text-[35px] font-tertiary text-gradient mb-2'>
                  {
                    inView ?
                    <CountUp start={0} end={3} duration={4} /> : null}
                    +
                </div>
                <div className='font-primary text-sm tracking-[2px]'>
                  Years of<br />
                  Experience
                </div>
              </div>
              <div>
                <div className='text-[35px] font-tertiary text-gradient mb-2'>
                  {
                    inView ?
                    <CountUp start={0} end={12} duration={4} /> : null}
                    
                </div>
                <div className='font-primary text-sm tracking-[2px]'>
                  Projects<br />
                  Completed
                </div>
              </div>
              <div>
                <div className='text-[35px] font-tertiary text-gradient mb-2'>
                  {
                    inView ?
                    <CountUp start={0} end={6} duration={4} /> : null}
                    +
                </div>
                <div className='font-primary text-sm tracking-[2px]'>
                Languages<br />
                  Learned
                </div>
              </div>
           </div>
        </motion.div>
      </div>
    </div>
    </section>
  );
};

export default About;
