import React from 'react';
// motion
import {motion} from 'framer-motion'
// variants
import { fadeIn } from '../variants';

// The rest of the code...

const Contact = () => {
  return (
    <section className='py-16 lg:section' id='contact'>
      <div className="container mx-auto">
        <div className='flex flex-col lg:flex-row'>
          {/* text */}
          <motion.div
            variants={fadeIn('right', 0.3)}
            initial="hidden"
            whileInView={"show"}
            viewport={{once: false, amount: 0.3}} 
            className='flex-1 flex justify-start items-center'>
            <div>
              <h4 className='text-xl uppercase text-accent font-medium mb-2 tracking-wide '>
                Get in touch
              </h4>
              <h2 className='text-[45px] lg:text-[90px] leading-none mb-12'>
                Lets <br /> Talk!
              </h2>
            </div>
          </motion.div>
          {/* form */}
          <motion.form
            action="https://formspree.io/f/mrgwlgvj" method="POST"
            variants={fadeIn('left', 0.3)}
            initial="hidden"
            whileInView={"show"}
            viewport={{once: false, amount: 0.3}} 
            className='flex-1 border rounded-2xl flex flex-col gap-y-6 pb-24 p-6'>
            <input 
              className='bg-transparent border-b py-3 outline-none w-full placeholder:text-white focus:border-accent transition-all' 
              type='text'
              placeholder='Your name'
              name='name' // Use 'name' for the name input
              required
            />
            <input 
              className='bg-transparent border-b py-3 outline-none w-full placeholder:text-white focus:border-accent transition-all' 
              type='email' // Use type 'email' for email input
              placeholder='Your email'
              name='_replyto' // Use '_replyto' for Formspree to recognize the email field
              required
            />
            <textarea 
              className='bg-transparent border-b py-12 outline-none w-full placeholder:text-white focus:border-accent transition-all resize-none mb-12' 
              placeholder='Your message' 
              name='message' // Use 'message' for the message input
              required
            ></textarea>
            <button type="submit" className='btn btn-lg'>Send message</button>
          </motion.form>
        </div>
      </div>
    </section>
  );
};



export default Contact;
